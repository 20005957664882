body main {
  outline: none !important;
}

.KnBrLoginPageTemplate,
.knBrQuoteSellerApproverTemplate {
  display: flex;
  justify-content: center;
}

.KnBrLoginPageTemplate .SiteLogo,
.knBrQuoteSellerApproverTemplate .SiteLogo {
  position: absolute;
  text-align: center;
  top: 55px;
  z-index: 10;
}

.KnBrLoginPageTemplate .SiteTitle,
.knBrQuoteSellerApproverTemplate .SiteTitle {
  position: absolute;
  z-index: 9999;
  top: 160px;
}

.KnBrLoginPageTemplate .LeftContentSlot cx-media img,
.knBrQuoteSellerApproverTemplate .LeftContentSlot cx-media img {
  width: 100%;
  height: 100%;
  z-index: 0;
  max-height: calc(100vh - 103px);

  @media (min-width: 320px) and (max-width: 767px) {
    min-height: 107vh;
  }
}

.KnBrLoginPageTemplate .SiteLogo cx-media,
.knBrQuoteSellerApproverTemplate .SiteLogo cx-media {
  height: 100px;
  width: 380px;
}

.KnBrLoginPageTemplate .SiteLogo img,
.knBrQuoteSellerApproverTemplate .SiteLogo img {
  width: 100%;
  max-height: none;
}

// .KnBrLoginPageTemplate cx-media {
//   display: block;
// }
.KnBrLoginPageTemplate .SiteTitle.has-components p,
.knBrQuoteSellerApproverTemplate .SiteTitle.has-components p {
  color: var(--cx-color-inverse);
  font-size: 21px;
}

.login-forms .form-group i.fas.fa-user {
  position: absolute;
  left: 18px;
  top: 10px;
}

.login-forms .form-group i.fas.fa-lock {
  position: absolute;
  left: 18px;
  top: 10px;
}

.login-forms .form-group input.form-control {
  padding: 10px 48px;
}

.forget-pass a:hover {
  color: var(--cx-color-secondary);
  text-decoration: none;
}

.KnBrLoginPageTemplate .LeftContentSlot kn-br-login-form.knbr-user-form {
  position: inherit !important;

  form {
    .knbr-login-forms {
      .login-forms {
        .login-btns {
          button {
            flex: initial !important;
          }
        }
      }
    }
  }
}
