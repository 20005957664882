cx-global-message {
  z-index: 9999;
  position: fixed;
  top: 24px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

cx-global-message .alert .close {
  right: 1.25rem;
}
