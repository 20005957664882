  $cx-buttons-border-radius: 0.25rem;

input::placeholder {
  color: #929292 !important;
}

.btn {
  border-radius: $cx-buttons-border-radius !important;
}

.btn-primary:not(:disabled):not(.disabled):active {
  filter: brightness(calc(88 / 100)) !important;
}

.btn-primary:hover {
  filter: brightness(calc(88 / 100));
}
.customer-context{
  color:#5e5e5eed
}

.fa-lg {
  line-height: 1em !important;
}
cx-icon ,.fa-times{

  -webkit-text-stroke: 1.1px
}
