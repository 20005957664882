@import '@spartacus/styles/index';
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/grid";
// @import "~bootstrap/scss/utilities";
// @import "~bootstrap/scss/_custom-forms";
// @import '~bootstrap/scss/buttons';
@import './styles/spartacus/index';
@import './assets/scss/_index';
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~@fontsource/open-sans/300.css";
