%cx-customer-ticketing-list {
  .cx-ticketing-list-title {
    display: none;
  }

  .cx-ticketing-list-thead-mobile{
    background: $customer-titles;
    color: $gray-600;
    cursor: $custom-range-track-cursor;
    font-size: $font-size-base;
  }

  .cx-ticketing-list-table {
    padding: 1.5rem 0 1.125rem 0;
    margin-bottom: 0;
    border-top: 1px solid var(--cx-color-light);

    @include media-breakpoint-down(sm) {
      display: flex;
      padding: 0;
    }

    tbody {
      @include media-breakpoint-down(sm) {
        display: flex;
        padding: 0;
        flex-direction: column;
        width: 100%;
      }
      tr:hover{
      -webkit-box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 1px 3px 1px rgba(60, 64, 67, 0.15);
      -moz-box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 1px 3px 1px rgba(60, 64, 67, 0.15);
      box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
        0 1px 3px 1px rgba(60, 64, 67, 0.15);
      }
    }

    tr {
      &:first-child {
        padding-top: 0;
      }
    }

    th {
      padding: 1.5rem 1.25rem 1.125rem 1.25rem;
      text-align: start;

      &:last-child {
        text-align: end;
      }
    }

    td {
      padding: 1.625rem 1.25rem;
      font-size: var(--cx-font-size, 1rem);

      a {
        min-height: 0px;
        min-width: 0px;
      }

      @include media-breakpoint-up(md) {
        text-align: start;

        &:last-child {
          text-align: end;
        }
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        display: flex;
        border-width: 0;
        padding: 0 1.25rem;

        &:first-child {
          padding-top: 1.25rem;
        }

        &:last-child {
          padding-bottom: 1.25rem;
        }
      }
    }
  }

  .cx-ticketing-list-thead-mobile {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .cx-ticketing-list-label {
    color: var(--cx-color-secondary);

    @include media-breakpoint-up(md) {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      font-size: var(--cx-font-size, 0.875rem);
      font-weight: var(--cx-font-weight-bold);
      min-width: 140px;
    }
  }

  .cx-ticketing-list-id {
    text-decoration: underline;
  }

  .cx-ticketing-list-value {
    color: var(--cx-color-text);
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    hyphens: auto;

    @include media-breakpoint-up(sm) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    @include media-breakpoint-down(sm) {
      font-size: var(--cx-font-size, 1rem);
      font-weight: $font-weight-normal;
      white-space: nowrap;
    }
  }

  .cx-ticketing-list-data {
    text-align: center;

    @include media-breakpoint-down(sm) {
      text-align: start;
    }
  }

  .cx-ticketing-list-sort {
    display: flex;
    margin: 0;

    &.top, &.bottom {
      justify-content: flex-end;
      padding: 1rem 0;
    }
  }

  .cx-ticketing-list-form-group {
    padding: 0;
    margin-bottom: 0;
    align-items: center;
    display: flex;
    width: 40%;
    transform: translate(121px, 10px);

    span {
      margin-inline-end: 0.5rem;
      flex: none;
    }

    cx-sorting {
      width: 100%;
    }
  }

  .cx-text-green {
    color: var(--cx-color-success);
    font-weight: var(--cx-font-weight-bold);
  }

  .cx-text-grey {
    color: var(--cx-color-secondary);
  }
}
