  kn-br-quote-search,
  app-kn-br-invoice-list-search,
  knbr-kn-br-return-order-search,
  knbr-kn-br-order-history-search,
  kn-br-case-listing-result,
  kn-br-case-create {
    .multi-select-styles {
      .ng-select-container {
        padding: 0px !important
      }
    }

    ng-select {

      .ng-input {
        input {
          padding-top: 8px !important;
        }
      }

      .ng-option {
        white-space: normal !important;

        .row {
          flex-wrap: nowrap;
          gap: 5px;
        }

        input[type=checkbox] {
          margin-inline-start: 1rem;
          position: unset;
          height: 12px !important;
          width: 15px !important;
          margin-top: 7px;
        }
      }

      .ng-placeholder {
        top: 18px !important;
      }

      .ng-value-container {
        flex-wrap: nowrap !important;
        overflow: hidden;
      }
    }
    @media screen and (max-width: 480px) {
     .ng-value-container  {
       .ng-value {
          white-space: pre-wrap;
     }
     }
  }
}

knbr-kn-br-shipping {
  .multi-select-styles {
    .ng-select-container {
      padding: 0px !important
    }
  }

  ng-select {

    .ng-input {
      input {
        padding-top: 8px !important;
      }
    }

    .ng-option {
      white-space: normal !important;

      .row {
        flex-wrap: nowrap;
        gap: 5px;
      }

      input[type=checkbox] {
        margin-inline-start: 1rem;
        position: unset;
        height: 12px !important;
        width: 15px !important;
        margin-top: 7px;
      }
    }

    .ng-placeholder {
      top: 18px !important;
    }

    .ng-value-container {
      flex-wrap: nowrap !important;
      overflow: hidden;
    }
  }
}
