%cx-customer-ticketing-close,
%cx-customer-ticketing-reopen {
  display: flex;
  justify-content: flex-end;

  @include media-breakpoint-down(sm) {
    div {
      justify-content: center;
      display: flex;
    }
  }

  button {
    margin: 1rem;
  }
}

%cx-customer-ticketing-create {
  display: flex;
  justify-content: flex-end;
  transform: translateY(calc(-32px - 49%));

  @include media-breakpoint-down(sm) {
    div {
      justify-content: center;
      display: flex;
    }
  }
}
