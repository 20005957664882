kn-br-product-summary {
  display: block;
  flex-direction: column;
  flex-grow: 0;

  @include media-breakpoint-down(md) {
    padding-top: 1.5em;
  }

  @include media-breakpoint-up(lg) {
    padding: 0 0 20px 20px;
  }

  .price {
    @extend .color-font;
    display: inline-flex;
    line-height: 1.3;

    label {
      @extend .font-text-600;
      @extend .mr-2;
    }
  }

  .quantity {
    label {
      @include type('8');
      margin: 15px 0 10px 0;
    }

    .info {
      @include type('7');
      margin: 0 15px 0 15px;
      color: var(--cx-color-secondary);
    }
  }

  kn-br-add-to-cart {
    margin: 20px 0 10px 0;
  }
}
