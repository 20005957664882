.kn-br-form-errors {
  p {
    font-size: 14px;
    margin: 6px 0;
    padding: 0 0 0 25px;
    position: relative;
    word-break: break-word;

    &::before,
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 20px;
      height: 20px;
    }

    &::before {
      content: '';
      background-color: var(--cx-color-danger);
      border-radius: 50%;
    }

    &::after {
      content: '!';
      color: var(--cx-color-inverse);
      font-weight: var(--cx-font-weight-bold);
      text-align: center;
      line-height: 20px;
    }

    &:focus {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
}
