%cx-product-attributes {
  table {
    width: 100%;
    margin: 0 0 30px 0;
    th {
      h3 {
        margin: 0 0 18px 0;
      }
    }
    tr {
      border: none;
      padding: 0 15px 0 15px;
    }
    td {
      border-bottom: 1px solid var(--cx-color-light);
      padding: 15px 0 15px 0;
      vertical-align: middle;
      width: 50%;
      font-weight: 500;
      float: left;
      display: flex;
      align-items: center;
      word-break: break-word;
    }
    ul {
      list-style: none;
      padding: 0 0 0 0;
      margin-bottom: 0;
      font-weight: 400;
    }
    ul li img {
      width: 50px;
    }
  }
}

cx-product-details-tab {
  table {
    width: 100%;
    margin: 0 0 30px 0;
    th {
      h3 {
        margin: 0 0 18px 0;
      }
    }
    tr {
      padding: 0 15px 0 15px;
    }
    td {
      border-bottom: 1px solid var(--cx-color-light);
      padding: 15px 0 15px 0;
      vertical-align: middle;
      width: 50%;
      font-weight: 500;
      float: left;
      display: flex;
      align-items: center;
      word-break: break-word;
    }
    ul {
      list-style: none;
      padding: 0 0 0 0;
      margin-bottom: 0;
      font-weight: 400;
    }
  }
}

cx-product-technical-documents {
  table {
    width: 100%;
    margin: 0 0 30px 0;
    th {
      h3 {
        margin: 0 0 18px 0;
      }
    }
    tr {
      padding: 0 15px 0 15px;
    }
    td {
      border-bottom: 1px solid var(--cx-color-light);
      padding: 15px 0 15px 0;
      vertical-align: middle;
      width: 50%;
      font-weight: 500;
      float: left;
      display: flex;
      align-items: center;
      word-break: break-word;
    }
    ul {
      list-style: none;
      padding: 0 0 0 0;
      margin-bottom: 0;
      font-weight: 400;
    }

    ul li .certification-name span {
      font-weight: 500;
      word-break: break-word;
      margin-right: 20px;
    }

    ul li img {
      width: 65px;
    }
  }
}

cx-product-certifications-tab {
  table {
    width: 100%;
    margin: 0 0 30px 0;
    th {
      h3 {
        margin: 0 0 18px 0;
      }
    }
    tr {
      border: none;
      padding: 0 15px 0 15px;
    }
    td {
      border-bottom: 1px solid var(--cx-color-light);
      padding: 15px 0 15px 0 !important;
      vertical-align: middle;
      width: 50%;
      font-weight: 500;
      float: left;
      display: flex;
      align-items: center;
      word-break: break-word;
    }

    td .certification-name span {
      font-weight: 500;
      word-break: break-word;
      margin-right: 25px;
      width: 65%;
    }

    td img {
      width: 60px;
    }
  }
}

cx-page-layout.KnBrProductDetailsPageTemplate cx-product-features {
  table ul li p {
    font-weight: 500;
    margin: 0 0 10px 0;
  }

  table ul {
    list-style: none;
  }

  table .html-tag ul {
    padding-left: 0px !important;
  }

  table ul li {
    list-style: disc;
    margin-left: 0px;
  }

  table .html-tag ul li {
    list-style: none;
    margin-left: 0px;
  }
}
