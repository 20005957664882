@import './app-vriables';
@import './app-black-theme.scss';
//Common Contains
@import './common/_.container';
@import './common/_.tab-container';
@import './knack_asm.scss';
@import './knack-app';
@import './knack-app-responsive';
@import './Login';
@import './side-navbar';
@import './knack-facet-navigation.scss';
// Product Detail Style
@import './products/knack-product';
// Cart and Counter css
@import './carts/_.index';

// Global Message Style
@import './common/_global_message';

@import './common/.kn-br-form-errors';

@import './knack-customer-home.scss';
@import './components/index';
@import './quotes/quote-search.scss';


// for demo
kn-br-mini-cart, kn-br-add-to-cart, kn-br-add-to-quote,knbr-kn-br-order-details-actions {
    display: none;
}

.brand-box-style {
  gap: 20px;

  .check-box {
    margin-left: -13px;
    accent-color: var(--cx-color-primary);
  }

  .brand-box {
    border: 1px solid darkgrey;
    padding: 15px;
    padding-bottom: 0px;
  }
  svg {
    margin-left: 35px;
    width: 100px !important;
  }

  input[type=checkbox], input[type=radio] {
    margin-inline-start: 0rem;
    height: 14px;
    width: 18px;
  }
}

.category-styles {
  margin-left: 20px;
  margin-top: 16px;
  label {
    font-size: 0.875rem;
    color: #5e5e5e;
    font-weight: normal;
    text-decoration: none;
    margin-right: 50px;
  }

  input[type=checkbox], input[type=radio] {
    height: 12px;
    width: 12px;
    margin-inline-start: -1.25rem;
  }
}

knbr-kn-br-fact-sheet-header {

  .card-header {
    display: flex;
    justify-content: flex-end;
    padding: 0px 20px !important;
    margin-top: 30px;

    .btn-link {
      padding-bottom: 1px;
    }
  }

  .card-header button:not(.collapsed)::after  {
      text-decoration: none;
      content: '\2303';
      text-align: left;
      float: right;
      position: relative;
      color: black;
      font-size:20px;
      right: -17px;
  }

  .card-header button.btn.collapsed::after {
    text-decoration: none;
    content: '\2304';
    text-align: left;
    float: right;
    position: relative;
    color: black;
    font-size: 27px;
    right: -17px;
    bottom: 14px;
  }
}
