/* :root {
  --cx-color-primary: #000;
} */

$counter-color: var(--cx-color-inverse);
$cart-color: #0067b2;

.counters-section h4 {
  color: var(--cx-color-primary);
}

.counters-section h1 {
  color: $filter-color;
}

.counters-section .counter-icons i {
  color: $black-color;
}
