kn-br-product-info-detail {
  border-left: 1px solid #CCC;
  border-right: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  .pdp-accordion-styles {
    display: table-footer-group;
    .card {
      border-top: 1px solid #CCC !important;
      padding-left: 15px;
      margin-bottom: 12px;
      &-header {
        padding: 0px;
        background-color: white;

        .btn-link {
          color: #444;
          font-size: 18px !important;
          text-decoration: none;
          padding-left: 0px;
          width: 95vw;
        }
      }
    }

    .card-body {
      padding: 0 !important;
      a {
        text-decoration: underline;
      }
      i {
        color: var(--cx-color-primary);
        padding-left: 15px;
      }
    }

    // .arrow-styles {
    //   color: #00509E;
    // }
  }

  .pr-detail{
    button:first-of-type {
      border-top: none !important;
    }
  }
}
