kn-br-product-intro {
  @extend .color-font;

  @include media-breakpoint-up(lg) {
    padding: 0 0 0 20px;
  }

  .summary {
    @extend .font-xl-size;
    @extend .font-text-bold;
    width: auto;
    display: inline-grid;
  }

  button.btn {
    background: #dee2e6;
    color: #212529;
    width: auto;
    max-height: -webkit-min-content;
    max-height: -moz-min-content;
    max-height: min-content;
    height: auto;
  }

  .code {
    @extend .color-font;
    @extend .font-lg-size;
    display: inline-flex;

    label {
      @extend .font-text-600;
      @extend .mr-2;
    }
  }

  .rating {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .count {
      margin: 0 20px 0 5px;
    }

    cx-star-rating {
      padding: 0 0 15px 0;
    }
  }
}
