@use 'sass:math';
html, body {
  height: 100%;
}

.titleName {
  color: #516269;
}

body {
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif !important;
  outline: none !important;
  display: flex;
  flex-direction: column;
}

// main {
//   display: flex;
//   flex: 1;
//   justify-content: center;
//   align-items: center;
// }

:focus {
  outline: none !important;
}

.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked, .ng-select.ng-select-single .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #abc3f0;
}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
  border-color: rgb(61, 90, 235);
}

a:hover {
  text-decoration: none;
}
main cx-page-layout.LandingPage2Template {

  padding: 15px;
  max-width: 100%;
  margin-top: 108px;
}

.LandingPage2Template cx-page-slot.Section1 {
  max-width: 100% !important;
  margin: 0 !important;
}

::-webkit-scrollbar {
  width: 5px;
}

.btn-primary {
  font-size: 1rem;
  height: auto;

  &:hover {
    color: var(--cx-color-inverse);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-primary);
  }

  &:focus {
    color: var(--cx-color-inverse);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-primary);
  }

  &:not(:disabled):not(.disabled):active {
    color: var(--cx-color-inverse);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-primary);
  }

  &:disabled {
    color: var(--cx-color-inverse);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-primary);
    opacity: 0.65;
    cursor: not-allowed;
  }
}

.btn-secondary {
  font-size: 1rem;
  height: auto;
}

.btn-outline-primary {
  font-size: 1rem;
  height: auto;
  color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
  font-weight: normal;

  &:hover {
    color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-inverse);
  }

  &:not(:disabled):not(.disabled):active {
    color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-inverse);
  }

  &:disabled {
    color: var(--cx-color-light);
    border-color: var(--cx-color-light);
    opacity: 0.65;
    cursor: not-allowed;
  }
}

.btn-action {
  @extend .btn-outline-text !optional;
  border-width: 2px;
  border-style: solid;
  font-size: 1rem;
  height: auto;

  &:hover {
    color: var(--cx-color-primary);
    border-width: 2px;
    border-style: solid;
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-inverse);
  }

  &:not(:disabled):not(.disabled):active {
    color: var(--cx-color-inverse);
    border-width: 2px;
    border-style: solid;
    background-color: var(--cx-color-primary);
  }

  &:disabled {
    color: var(--cx-color-light);
    border-width: 2px;
    border-style: solid;
    border-color: var(--cx-color-light);
    opacity: 0.65;
    cursor: not-allowed;
  }
}

.btn-loadmore {
  font-size: 1rem;
  height: auto;
  color: var(--cx-color-inverse);
  background: var(--cx-color-primary);
  border: 0;
  width: auto;

  &:hover {
    color: var(--cx-color-inverse);
    background-color: $filter-color;
  }

  &:not(:disabled):not(.disabled):active {
    color: var(--cx-color-inverse);
    background-color: var(--cx-color-primary);
  }
}

.download-links {
  text-transform: capitalize;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;

  &:hover {
    color: var(--cx-color-primary);
    text-decoration: none;
  }
}

.hover-link {
  &:hover {
    color: var(--cx-color-inverse);
    text-decoration: none;
  }
}

.menu-inner-titles {
  &:hover {
    background: $gray-300;
  }
}

.custom-control {
  position: relative;
  display: block;
  min-height: $font-size-base * $line-height-base;
  padding-left: $custom-control-gutter + $custom-control-indicator-size;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: $custom-control-spacer-x;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1; // Put the input behind the label so it doesn't overlay text
  width: $custom-control-indicator-size;
  height: math.div($font-size-base * $line-height-base + $custom-control-indicator-size, 2);
  opacity: 0;

  &:checked~.custom-control-label::before {
    color: $custom-control-indicator-checked-color;
    border-color: var(--cx-color-primary);
    @include gradient-bg(var(--cx-color-primary));
    @include box-shadow($box-shadow-0);
  }

  &:focus~.custom-control-label::before {

    // the mixin is not used here to make sure there is feedback
    @if $enable-shadows {
      box-shadow: $box-shadow-0;
    }

    @else {
      box-shadow: $box-shadow-0;
    }
  }

  &:focus:not(:checked)~.custom-control-label::before {
    border-color: var(--cx-color-primary);
    @include box-shadow($box-shadow-0);
  }

  &:not(:disabled):active~.custom-control-label::before {
    color: $custom-control-indicator-active-color;
    background-color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
  }
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  color: $custom-control-label-color;
  vertical-align: top;
  cursor: $custom-control-cursor;

  // Background-color and (when enabled) gradient
  &::before {
    position: absolute;
    top: math.div($font-size-base * $line-height-base - $custom-control-indicator-size, 2);
    left: -($custom-control-gutter + $custom-control-indicator-size);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    pointer-events: none;
    content: '';
    background-color: $custom-control-indicator-bg;
    border: $filter-color solid 2px;
    @include box-shadow($custom-control-indicator-box-shadow);
  }
}

// Select all checkbox styling

.select-all .custom-control {
  position: relative;
  display: block;
  min-height: $font-size-base * $line-height-base;
  padding-left: $custom-control-gutter + $custom-control-indicator-size;
}

.select-all .custom-control-inline {
  display: inline-flex;
  margin-right: $custom-control-spacer-x;
}

.select-all .custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1; // Put the input behind the label so it doesn't overlay text
  width: $custom-control-indicator-size;
  height: math.div($font-size-base * $line-height-base + $custom-control-indicator-size, 2);
  opacity: 0;

  &:checked~.custom-control-label::before {
    color: var(--cx-color-primary);
    border-color: var(--cx-color-inverse);
    @include gradient-bg($white);
    @include box-shadow($box-shadow-0);
  }

  &:focus~.custom-control-label::before {

    // the mixin is not used here to make sure there is feedback
    @if $enable-shadows {
      box-shadow: $box-shadow-0;
    }

    @else {
      box-shadow: $box-shadow-0;
    }
  }

  &:focus:not(:checked)~.custom-control-label::before {
    border-color: var(--cx-color-inverse);
    @include box-shadow($box-shadow-0);
  }

  &:not(:disabled):active~.custom-control-label::before {
    color: $custom-control-indicator-active-color;
    background-color: var(--cx-color-inverse);
    border-color: var(--cx-color-inverse);
  }
}

.select-all .custom-control-label {
  position: relative;
  margin-bottom: 0;
  color: $custom-control-label-color;
  vertical-align: top;
  cursor: $custom-control-cursor;

  // Background-color and (when enabled) gradient
  &::before {
    position: absolute;
    top: math.div($font-size-base * $line-height-base - $custom-control-indicator-size, 2);
    left: -($custom-control-gutter + $custom-control-indicator-size);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    pointer-events: none;
    content: '';
    background-color: $custom-control-indicator-bg;
    border: $custom-control-indicator-border-color solid $custom-control-indicator-border-width;
    @include box-shadow($custom-control-indicator-box-shadow);
  }
}

.select-all .custom-control-input:checked~.custom-control-label {
  &::after {
    position: absolute;
    top: 9px;
    left: -20px;
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    content: '';
    background: no-repeat #{url(../images/select-all.png)};
  }
}

.reference-checkbox input[type='radio'] {
  border-radius: 50%;
  display: inline-block;
  border-style: solid;
  outline: none;
  border-width: 2px;
  border-color: $filter-color;
  position: absolute;
  top: -7px;

  &::after {
    content: '';
    position: absolute;
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--cx-color-inverse);
    left: 4px;
    top: 4px;
  }

  &:checked {
    border-color: $filter-color !important;
    background-color: var(--cx-color-inverse);

    &::after {
      background-color: var(--cx-color-primary);
    }
  }
}

a:hover {
  color: var(--cx-color-primary);
}

kn-br-add-to-cart .quantity cx-item-counter {
  button {
    font-size: $counter-font;

    &:hover {
      color: var(--cx-color-inverse);
    }
  }
}

.MultiStepCheckoutSummaryPageTemplate,
.KnBrOrderConfirmationPageTemplate {
  cx-promotions .cx-promotions:empty {
    display: none;
  }
}

.brand:empty {
  display: none;
}

.KnBrQuoteDetailPageTemplate,
.KnBrDraftOrderDetailPageTemplate {
  kn-br-product-quick-entry:empty {
    display: none;
  }
}

.LandingPage2Template cx-page-slot.Section2.has-components {
  background: var(--cx-color-inverse);
  margin-top: 15px;
  padding: 15px;
  border-radius: 4px;
}

.product-grid-hover mat-card {
  width: 100%;
}

::-webkit-scrollbar-track {
  border-radius: 0px;
}

::-webkit-scrollbar-thumb {
  background: var(--cx-color-primary);
  border-radius: 34px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--cx-color-primary);
}

button {
  outline: none !important;
  box-shadow: none !important;
}

.bg-white.rounded {
  min-height: calc(100vh - 356px) !important;
}

// Login Styling starts
.login-wrapper {
  position: relative;
  position: absolute;
  top: 190px;
}

.login-wrapper img.login-back {
  width: 100%;
}

.remember-me input {
  border-radius: $custom-checkbox-indicator-border-radius;
}

.knbr-logo {
  position: absolute;
  left: 0;
  top: 90px;
}

.login-btns button.btn {
  padding: $table-cell-padding;
  font-size: $login-font-size;
  font-weight: $headings-font-weight;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0 !important;
  box-shadow: $box-shadow-0;
}

.login-forms {
  background: $card-bg;
  border-radius: $border-radius;
  padding: $mark-padding;
  float: left;
}

.login-forms .form-group {
  position: relative;
  outline: none;
}

.login-forms .form-group input.form-control,
.head-notes textarea.form-control {
  padding: 20px 14px 20px 14px;
  background: none;
  color: $gray-800;
  outline: none;
  height: auto;
  line-height: normal;
  display: block;
  width: 300px;
  border: none;
  border: 1px solid $gray-500;
  outline: $pagination-focus-outline;
}

.login-forms .form-group select.form-control {
  padding: 20px 14px 20px 14px;
  background: none;
  color: $gray-800;
  height: auto;
  line-height: normal;
  display: block;
  width: 300px;
  border: none;
  border: 1px solid $gray-500;
  -webkit-appearance: none;
}

.login-forms .form-group input:focus {
  outline: none;
}

.login-forms .form-group select:focus {
  outline: none;
}

.login-forms .form-group label {
  color: $gray-600;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.login-forms .form-group label {
  top: -12px;
  left: 14px;
  font-size: 14px;
  color: $gray-800;
  padding: 0px 5px;
  background: var(--cx-color-inverse);
  z-index: 1;
}

// Login Styling end
// Footer Styling starts
.knbr-footerTop .footer-nav ul {
  list-style: none;
}

// Footer Styling end
// Header Styling starts

header .SiteLogo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: auto;
}


header .SiteLogo cx-media img {
  width: 135px;
  height: auto;
}

header .SiteLogo kn-br-banner {
  flex: initial;
}

header cx-page-layout.header .SearchBox {
  margin: 0 1.5rem;
  // display: flex;
  // align-items: center;
  flex: auto;
  justify-content: left;

  cx-searchbox label.searchbox {
    width: 24vw;
    padding: 2px 10px !important;
  }
}

header .SiteContext:after {
  border-top: none;
}

header .SiteContext {
  flex: auto;
  align-items: center;
  cursor: $custom-range-track-cursor;
  background: none;
  margin-right: 5px;
  justify-content: flex-end;
  position: relative;
}

header .SiteContext span.cart-context .res-cart-context {
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left;
  overflow: hidden;
  max-width: 350px;
}

header .SiteLinks {
  flex: initial;
  align-items: center;
  background: none;
}

header .SiteLogo .top-bar-nav {
  flex: initial;
}

header .header {
  max-width: 100%;
  padding: 0 15px;
  position: fixed;
  z-index: 1000;
  background-color: var(--cx-color-header);
  min-height: 53px;
}

.breadcrumb .selected-pages ul {
  list-style: none;
  font-size: 1.5rem;
  color: $breadcrumb-title;
  font-weight: $font-weight-bold;
}

header .header .SiteLinks cx-generic-link a::after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f011';
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  text-decoration: none;
  color: var( --cx-color-primary);
  font-size: 20px;
  position: absolute;
  top: 12px;
  right: 0;
}

cx-storefront.asm-storefront cx-page-layout.header .SiteLinks cx-generic-link a::after {
  right: 0;
}

header .header .SiteLinks cx-generic-link a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 0;
  float: right;
  margin: 16px 8px;
}

header .SiteContext cx-site-context-selector label select {
  background-color: var(--cx-color-primary);
  color: var(--cx-color-inverse);
  font-size: 15px;
  cursor: $custom-range-track-cursor;
}

header .SiteContext cx-site-context-selector label cx-icon {
  background-color: var(--cx-color-primary);
  color: var(--cx-color-inverse);
  cursor: $custom-range-track-cursor;
}

.breadcrumb .selected-pages ul li p {
  font-size: 1.5rem;
  color: $black-color;
  font-weight: $font-weight-bold;
  text-decoration: none;
}

.head-breadcrumb {
  z-index: $zindex-modal;
}

.cart-count.MiniCart a {
  background-color: $cart-color;
  padding: 8px;
  height: auto;
  border-radius: $btn-border-radius;
  width: auto;
  color: var(--cx-color-inverse);
  flex-direction: inherit;
  float: right;
  min-width: auto;
  justify-content: flex-end;
  margin-right: 10px;

  &:hover {
    color: var(--cx-color-inverse);
  }
}



.cart-count.MiniCart a span.count {
  margin: 0;
}

.cart-count.MiniCart {
  width: 100%;
  position: relative;

  kn-br-mini-cart,
  knbr-order-tools {
    position: absolute;
  }

  knbr-order-tools {
    right: 0px;
    top: -20px;
  }

  kn-br-mini-cart {
    right: 140px;
    top: -20px;
  }

  @include media-breakpoint-down(sm) {
    kn-br-mini-cart,
    knbr-order-tools {
      position: static;

      a {
        float: none !important;
        clear: both;
      }
    }

  }
}

.text-align-right {
  padding-right: 25px;
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.knbr-dropdown {
  border-radius: 0.25rem;
  top: 0px !important;
  z-index: 9999;
  padding: 0;
}

.knbr-dropdown a.dropdown-item:focus,
.knbr-dropdown a.dropdown-item:active,
.knbr-dropdown a.dropdown-item:hover {
  background-color: var(--cx-color-primary);
  color: var(--cx-color-inverse);
}

.breadcrumb {
  position: relative;
  top: 61px;
  min-height: 58px;
  border-bottom: 1px solid lightgray;
}

.login-forms .form-group .ng-select-container {
  // z-index: 997 !important;
  border: 1px solid $gray-500;
}

// Header Styling end
// Home-page Styling starts
.counters-section {
  background: $counter-color;
  opacity: $tooltip-opacity;
}

.counters-section .counter-icons i {
  font-size: $counter-icon-size;
}

.container-fluid.home-container {
  background: $container-color;
  margin-top: 123px;
}

.customersearch-title {
  color: $breadcrumb-title;
  font-size: $h3-font-size;
  font-weight: bold;
  line-height: 1.15;
}

.login-forms .form-group i.fa.fa-caret-down {
  position: absolute;
  right: 27px;
  top: 9px;
  color: var(--cx-color-secondary);
}

.back-btn {
  background: $gray-300;
  color: $gray-900;
  width: auto;
  max-height: min-content;
  height: auto;
}

.customersearch-forms input.form-control {
  font-size: $font-size-base !important;
}

.customersearch-forms select.form-control {
  font-size: $font-size-base !important;
}

.customersearch-list-table .customersearch-list-titles {
  background: $customer-titles;
  color: $gray-600;
  cursor: $custom-range-track-cursor;
  font-size: $font-size-base;

  cx-media {
    width: 25%;
  }
}

.customersearch-list-titles.table-content:hover {
  -webkit-box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  -moz-box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.customersearch-list-table .customersearch-list-titles .title-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.hyperlink {
  a {
    text-decoration: underline !important;
    color: var(--cx-color-primary) !important;
    cursor: pointer !important;
  }
}

body .customersearch-forms.login-forms input.form-control,
body .customersearch-forms.login-forms .ng-select-container .ng-input>input {
  padding: 16px 14px;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  line-height: normal;
}

body .customersearch-forms.login-forms .ng-select-container .ng-input>input:disabled {
  background: $customer-titles;
  cursor: not-allowed;
}

body .customersearch-forms.login-forms .ng-select.ng-select-disabled>.ng-select-container {
  background-color: $customer-titles;
}

body .customersearch-forms.login-forms input.form-control:disabled {
  background: $customer-titles;
  cursor: not-allowed;
}

.customer-home-forms .ng-select-container {
  z-index: 0;
  padding: 16px 0 16px 5px !important;
  line-height: normal !important;
  color: $filter-color;
  outline: none;
  overflow: initial;
  border: 1px solid $gray-500 !important;
}

body .customersearch-forms.login-forms .form-group.country-form input {
  padding: 9px 7px;
}

select.selectState {
  padding: 16px 20px;
  background: none;
  color: $filter-color;
  height: auto;
  line-height: normal;
  display: block;
  width: 270px;
  border: 1px solid #adb5bd !important;
}

// Home-page Styling
// Product-Grid Styling starts
.product-action-section a.grid-icon {
  background:  var(--cx-color-primary);
  color: $white;
  font-size: 19px;
  width: 40px;
  height: 39px;
}

.product-name span {
  -webkit-box-orient: vertical;
}

.add-cart-quote button.btn {
  opacity: 0.8;
}

.select-all {
  right: -42px;
  top: 50%;
  transform: rotate(90deg);
  border-radius: $border-radius-lg;
  z-index: $zindex-tooltip;
}

cx-page-layout.ErrorPageTemplate {
  background: var(--cx-color-inverse);
  padding: 15px;
  width: auto;
  max-width: 100%;
  min-height: calc(100vh - 257px);
  border-radius: $border-radius;
  margin: 90px 15px 15px 15px;
}

cx-page-layout.ErrorPageTemplate {
  kn-br-banner {
    max-width: 400px;
  }

  cx-media {
    justify-content: center;
  }

  cx-page-slot.BottomContent cx-generic-link {
    float: left;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  cx-page-slot.BottomContent cx-generic-link a {
    background: var(--cx-color-primary);
    color: var(--cx-color-inverse);
    padding: 10px 33px;
    float: left;
    border-radius: 4px;
  }
}

cx-page-layout.CarlisleAccountDetailsPageTemplate {
  padding: 0px 35px;
}

cx-page-layout.CarlisleShippingListingPageTemplate {
  padding: 0px 10px !important;
  margin: 15px 0px 15px 15px !important;

  .customersearch-list-table .customersearch-list-titles {
    cursor: text !important;
  }
  .accordion {
    max-width: none;
    .btn-link{
      padding: 0px !important;
    }
    .shipment-delivery-details {
      font-size: 1rem !important;
      color: var(--cx--color-sec-gray) !important;
    }
  }
   .packing-list, .tracking-id {
    color: var(--cx--color-sec-gray) !important;
   }
  .downloadPdf
   {
    color: var(--cx-color-primary);
    cursor: pointer;
   }
   .fromDate, .toDate {
    color: red;
  }
}

cx-page-layout.KnBrProductListPageTemplate,
cx-page-layout.KnBrProductGridPageTemplate,
cx-page-layout.KnBrSearchResultsListPageTemplate,
cx-page-layout.KnBrSearchResultsGridPageTemplate,
cx-page-layout.KnBrDraftOrderPageTemplate,
cx-page-layout.KnBrQuotePageTemplate,
cx-page-layout.MultiStepCheckoutSummaryPageTemplate,
cx-page-layout.KnBrOrderConfirmationPageTemplate,
cx-page-layout.KnBrDraftOrderCreatePageTemplate,
cx-page-layout.KnBrQuoteCreatePageTemplate,
cx-page-layout.KnBrDraftOrderDetailPageTemplate,
cx-page-layout.KnBrLandingPage2Template,
cx-page-layout.KnBrCustomer360PageTemplate,
cx-page-layout.KnBrOrderHistoryPageTemplate,
cx-page-layout.KnBrOrderDetailsPageTemplate,
cx-page-layout.KnBrReturnHistoryPageTemplate,
cx-page-layout.CarlisleInvoiceDetailsPageTemplate,
cx-page-layout.KnBrInvoiceListPageTemplate,
cx-page-layout.KnBrAddressBookPageTemplate,
cx-page-layout.KnBrFactSheetPageTemplate,
cx-page-layout.KnBrOrderImportPageTemplate,
cx-page-layout.KnBrPurchasingAgentPageTemplate,
cx-page-layout.KnBrServiceTicketsPageTemplate,
cx-page-layout.KnBrQuickOrderPageTemplate,
cx-page-layout.KnBrOrderFormsPageTemplate,
cx-page-layout.KnBrQuickEntryPageTemplate,
cx-page-layout.AccountPageTemplate,
cx-page-layout.NACPageTemplate,
cx-page-layout.KnBrMarketCentrePageTemplate,
cx-page-layout.CompanyPageTemplate,
cx-page-layout.CarlisleShippingListingPageTemplate {
  // background: var(--cx-color-inverse);
  padding: 15px;
  width: auto;
  max-width: 100%;
  border-radius: $border-radius;
  margin: 65px 15px 15px 15px;
}
cx-page-layout.KnBrSearchResultsGridPageTemplate {
  margin: 75px 15px 15px 15px;
}
cx-page-layout.KnBrReturnDetailsPageTemplate {
  padding: 15px;
  width: auto;
  max-width: 100%;
  margin: -15px 15px 15px 10px;
}
cx-page-layout.KnBrQuoteDetailPageTemplate {
  width: auto;
  max-width: 100%;
  margin: 0px 25px 15px 20px;
}
cx-page-layout.AccountPageTemplate > cx-page-slot{
    max-width: 100% !important;
  }

cx-page-layout.ContentPage1Template {
  background: var(--cx-color-inverse);
  padding: 15px 100px;
  width: auto;
  max-width: 100%;
  border-radius: $border-radius;
  margin: 70px 15px 15px 15px;
}

.KnBrOrderConfirmationPageTemplate {
  .cx-page-header {
    background-color: $alart-success;
    border-radius: 4px;
  }

  .cx-order-confirmation-message {
    width: 100%;
    text-align: center;
    float: left;
    padding: 16px 0 30px;
  }

  .cx-order-confirmation-message h2 {
    color: $success;
    font-weight: bold;
  }

  .cx-order-confirmation-message p {
    float: left;
    width: 100%;
    margin: 0;
  }

  cx-order-confirmation-overview {
    margin-bottom: 20px;
  }

  cx-order-confirmation-totals cx-order-summary {
    padding: 0;
  }

  cx-order-confirmation-items .cx-order-items.container {
    max-width: 100%;
    padding: 0;
  }

  cx-order-confirmation-items {
    flex: 0 0 100%;
    float: left;
    // border-right: 1px solid $gray-500;
    // padding-right: 15px;
  }

  cx-order-confirmation-totals {
    // float: left;
    // width: auto;
    // flex: 0 0 30%;
    display: none;
  }

  cx-order-confirmation-totals .col-sm-12.col-md-6.col-lg-5.col-xl-4 {
    max-width: 100%;
    flex: 100%;
  }

  cx-order-confirmation-items .cx-item-list-header {
    background: $customer-titles;
    padding: 15px;
    color: $filter-color;
    text-transform: capitalize;
  }

  cx-cart-item-list .cx-image-container cx-media img {
    height: 70px;
  }

  cx-cart-item-list .cx-item-list-row {
    padding: 15px;
  }

  cx-cart-item-list .cx-image-container cx-media {
    justify-content: center;
  }

  cx-order-confirmation-items .cx-order-items.container h4.cx-order-items-header {
    padding: 0px 0 15px;
    text-transform: capitalize;
  }
}

.KnBrQuotePageTemplate,
.KnBrDraftOrderPageTemplate,
.KnBrQuoteDetailPageTemplate,
.KnBrDraftOrderDetailPageTemplate {

  .TopContent .KnBrQuoteListButtonSlot,
  .TopContent .KnBrDraftOrderListButtonSlot {
    cx-generic-link {
      width: 100%;

      a {
        width: 100%;
        text-align: center;
      }
    }
  }

  .TopContent cx-link a {
    background: var(--cx-color-primary);
    padding: 10px;
    float: left;
    color: var(--cx-color-inverse);
    border-radius: $border-radius;
    text-transform: capitalize;
  }

  .TopContent cx-link {
    display: flex;
    justify-content: flex-end;
  }

  .KnBrQuoteHeaderSlot cx-link,
  .KnBrEmptyCartSlot cx-link,
  .KnBrDraftOrderHeaderSlot cx-link {
    flex: 0 0 14%;
    width: auto;
    float: left;
  }

  .KnBrQuoteHeaderSlot cx-link cx-generic-link a,
  .KnBrDraftOrderHeaderSlot cx-link cx-generic-link a {
    color: var(--cx-color-inverse);
    font-size: 16px;
    float: left;
    text-align: center;
    padding: 7px 15px;
    background: var(--cx-color-primary);
    border-radius: 4px;
  }

  .KnBrQuoteHeaderSlot kn-br-quote-save {
    flex: 0 0 0;
    width: auto;
    float: left;
  }

  cx-page-slot.KnBrEmptyCartSlot {
    justify-content: center;
  }

  cx-page-slot.KnBrQuoteHeaderSlot,
  cx-page-slot.KnBrDraftOrderHeaderSlot {
    justify-content: flex-start;
    margin-top: 15px;
  }

  cx-page-slot.KnBrEmptyCartSlot {
    justify-content: center;
    margin-top: 15px;
  }

  cx-page-slot.KnBrQuoteActionBarSlot,
  cx-page-slot.KnBrDraftOrderActionBarSlot {
    justify-content: flex-start;
    margin-top: 15px;
  }

  .KnBrQuoteActionBarSlot kn-br-quote-delete-items,
  .KnBrDraftOrderActionBarSlot knbr-kn-br-draft-order-delete-items {
    flex: 0 0 61px;
    width: auto;
    float: left;
  }

  .KnBrQuoteActionBarSlot kn-br-product-quick-entry,
  .KnBrEmptyCartSlot kn-br-product-quick-entry,
  .KnBrDraftOrderActionBarSlot kn-br-product-quick-entry {
    flex: 0 0 130px;
    width: auto;
    float: left;
  }

  .KnBrQuoteActionBarSlot cx-link,
  .KnBrEmptyCartSlot cx-link,
  .KnBrDraftOrderActionBarSlot cx-link {
    flex: 0 0 150px;
    width: auto;
    float: left;
  }

  .KnBrQuoteActionBarSlot cx-link cx-generic-link a,
  .KnBrEmptyCartSlot cx-link cx-generic-link a,
  .KnBrDraftOrderActionBarSlot cx-link cx-generic-link a {
    border-radius: var(--cx-buttons-border-radius);
    border-width: 1px;
    border-style: solid;
    padding: 11px 13px;
    font-size: 1rem;
    height: auto;
    line-height: 1.34;
    float: left;
    color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
    background-color: var(--cx-color-inverse);

    &:hover {
      color: var(--cx-color-primary);
      border-color: var(--cx-color-primary);
      background-color: var(--cx-color-inverse);
    }
  }

  // kn-br-quote-item .customersearch-list-titles cx-media {
  //   width: 25%;
  //   min-height: 50px;
  //   float: left;
  // }

  kn-br-draft-order-item .customersearch-list-titles cx-media {
    width: 30%;
    min-height: 50px;
    float: left;
  }

  .product-name {
    float: right;
    width: 70%;
    color: $gray-600;

    p {
      margin-bottom: 3px;
    }
  }

  .product-name span {
    -webkit-line-clamp: 3;
  }

  knbr-kn-br-quote-download-link,
  knbr-kn-br-draft-order-download-link {
    flex: 1 0 15% !important;
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
  }
}

cx-page-layout.KnBrProductListPageTemplate,
cx-page-layout.KnBrProductGridPageTemplate,
cx-page-layout.KnBrSearchResultsListPageTemplate,
cx-page-layout.KnBrSearchResultsGridPageTemplate,
cx-page-layout.KnBrProductDetailsPageTemplate {
  .SearchBox.has-components {
    float: left;
    width: auto;
  }

  .SearchBox.has-components {
    float: left;
    width: auto;
    width: 25%;
  }

  .filter-section .fas.fa-times {
    display: none;
  }

  .product-action-section .ng-select-container {
    height: 39px !important;
  }

  .product-action-section cx-sorting {
    width: 75%;
  }

  .SearchBox.has-components label.searchbox {
    background: var(--cx-color-inverse);
    border: 1px solid $gray-500;
    border-radius: $border-radius;
    padding: 7px;
    width: 100%;
    direction: rtl;

    cx-icon {
      padding-right: 7px;
    }
  }

  .SearchBox.has-components label.searchbox input {
    height: auto;
    float: right;
    text-align: left;
    color: $filter-color;
    direction: ltr;
  }

  .Section1.has-components {
    width: 75%;
    height: auto;
    padding-left: 15px;
  }

  .ProductLeftRefinements {
    width: 25%;
    float: left;
  }

  .product-search-actions i.fa.fa-search {
    left: 20px;
    top: 10px;
  }

  .filter-by .accordion .card-header {
    padding: 0;
  }

  .accordion .collapse a.value {
    display: flex;
    align-items: center;
  }

  .filter-by .accordion .card-body {
    padding: 0;
  }

  .show-more {
    button {
      color: var(--cx-color-primary);
    }
  }

  .filter-by .accordion .card-header button.btn.btn-link {
    text-decoration: none;
    font-size: $login-font-size;
    font-weight: $font-weight-bold;
    width: 100%;
    text-align: left;
    display: block;
    color: $filter-color;
  }

  .filter-by .accordion .card-header button.btn.btn-link::after {
    text-decoration: none;
    content: '\2303';
    text-align: left;
    float: right;
    position: absolute;
    color: $filter-color;
    font-size:22px;
    top: 14px;
    right: 12px;
  }

  .filter-by .accordion .card-header button.btn.btn-link.collapsed::after {
    text-decoration: none;
    content: '\2304';
    text-align: left;
    float: right;
    position: absolute;
    color: $filter-color;
    font-size: 31px;
    top: -6px;
    right: 14px;
  }


  .filter-by .accordion .collapse {
    overflow: auto;
  }

  .customersearch-list-table .customersearch-list-titles cx-media {
    width: 20%;
    height: 50px;
    align-items: center;
    justify-content: center;
  }

  .ProductListSlot cx-media,
  .ProductGridSlot cx-media,
  .SearchResultsGridSlot cx-media,
  .SearchResultsListSlot cx-media {
    width: 100%;
    height: 80px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .selected-pages {
    max-width: 100%;
    float: left;
    padding: 10px 0 !important;
  }

  .selected-pages ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .selected-pages ul li {
    float: left;
    width: auto;
  }

  .selected-pages ul li:not(:last-child)::after {
    content: '/';
    position: relative;
    padding: 10px;
    font-weight: $font-weight-bold;
    color: $filter-color;
  }

  // .selected-pages ul li a {
  //   color: $filter-color;
  // }

  .customersearch-list-titles.product-list-content:hover {
    -webkit-box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    -moz-box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
    box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.15);
  }

  .show-more-spinner .loader::before {
    border-top-color: var(--cx-spinner-secondary-color);
    border-bottom-color: var(--cx-spinner-secondary-color);
    border-right-color: var(--cx-spinner-secondary-color);
    border-left-color: var(--cx-color-primary);
  }

  .KnBrProductDetailsPageTemplate cx-page-slot.Summary cx-media {
    width: 100%;
    justify-content: center;
  }
}

// checkout Design
.MultiStepCheckoutSummaryPageTemplate {
  position: relative;
}

.MultiStepCheckoutSummaryPageTemplate {
  cx-page-slot.BodyContent {
    padding-right: 15px;
    height: 100%;
  }

  cx-page-slot.SideContent {
    flex: 0 0 37% !important;
    border-left: 1px solid $gray-400;
  }

  h3.cx-checkout-title,
  h3.cx-review-title {
    padding: 25px 0;
    color: $filter-color;
    font-size: 21px;
    margin: 0;
    font-weight: bold;
  }

  cx-address-form .form-group {
    position: relative;
    width: 25%;
    max-width: 25%;
    float: left;
    padding-right: 15px;
    margin-bottom: 15px;
    padding-left: 0;
  }

  cx-address-form .form-group span.label-content {
    position: absolute;
    top: -11px;
    left: 14px;
    font-size: 14px;
    color: $filter-color;
    z-index: 1002;
    background: var(--cx-color-inverse);
  }

  cx-address-form .form-group .ng-select-container {
    padding: 12px 0 13px 5px !important;
    height: auto;
  }

  cx-address-form .form-group .ng-select-container .ng-input>input {
    padding: 7px 6px;
  }

  cx-address-form .form-group .form-control,
  cx-address-form .form-group .form-control:focus {
    padding: 13px 0 13px 15px !important;
    height: auto;
    background: var(--cx-color-inverse);
  }

  cx-address-form .row {
    float: left;
    width: 100%;
    margin: 0;
  }

  cx-address-form .col-md-12.col-lg-9 {
    max-width: 100%;
    flex: 100%;
    padding: 0;
  }

  cx-page-slot.SideContent cx-order-summary {
    padding: 0px 5px 0px 20px !important;
    text-transform: capitalize;
  }

  cx-page-slot.SideContent cx-order-summary h4 {
    color: $filter-color;
    font-size: 24px;
    font-weight: bold;
    text-transform: capitalize;
  }

  cx-cart-item-list .cx-image-container cx-media {
    justify-content: center;
  }

  cx-cart-item-list .cx-image-container cx-media img {
    height: 70px;
  }

  cx-order-summary .cx-summary-row {
    padding: 0.35rem 0;
  }

  cx-page-slot.SideContent cx-order-summary .cx-summary-partials {
    color: $filter-color;
    font-size: 15px;
  }

  cx-page-slot.SideContent cx-order-summary .cx-summary-row .card-body {
    padding: 0;
  }

  cx-page-slot.SideContent cx-order-summary .cx-summary-row h4.cx-card-title {
    font-size: 1rem;
  }

  cx-address-form {
    display: block;
    padding: inherit;
  }

  cx-delivery-mode .cx-checkout-btns {
    float: left;
    display: flex;
    width: 100%;
  }

  .cx-checkout-btns {
    float: left;
    display: flex;
    margin: 0;
    width: 100%;
  }

  cx-page-slot.BottomContent kn-br-paragraph {
    padding: 20px 0 0;
    display: flex;
    justify-content: center;
  }

  .cx-review-summary-edit-step {
    padding: 10px;
    font-weight: 600;
  }

  .cx-review-cart-total {
    padding: 30px 0px 10px;
    color: $filter-color;
    font-size: 18px;
    font-weight: 600;
    margin-top: 0px;
  }

  cx-page-slot.SideContent cx-place-order {
    padding: 15px 0px 0 15px;
  }

  .cx-review-cart-item {
    padding: 0;
  }

  .cx-review-cart-item .cx-item-list-row {
    padding: 15px;
    border-bottom: 1px solid $filter-color;
  }

  .cx-review-cart-item .cx-item-list-header {
    background: $customer-titles;
    padding: 15px;
    color: $filter-color;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid $filter-color;
    text-transform: capitalize;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary:not(:disabled):not(.disabled).active:focus {
    background-color: var(--cx-color-primary);
  }

  cx-shipping-address .cx-checkout-body {
    max-height: 60vh;
    overflow: auto;
  }

  cx-shipping-address p.cx-checkout-text {
    color: $filter-color;
    margin: 0;
  }

  cx-shipping-address .cx-shipping-address-card {
    padding-bottom: 15px;
  }

  cx-page-slot.BodyContent .cx-checkout-btns .col-sm-12.col-md-12.col-lg-6 {
    padding-left: 0;
    float: left;
  }

  cx-page-slot.BodyContent .cx-checkout-btns {
    justify-content: flex-start;
  }

  .cx-card-fit-to-container .card-body.cx-card-body .cx-card-actions {
    display: none;
  }

  .cx-card-fit-to-container {
    border-radius: 4px;
  }

  .cx-card-fit-to-container .card-body.cx-card-body {
    color: $filter-color;
  }

  .cx-card-fit-to-container .card-header {
    background-color: $alart-success;
    color: $success-color;
  }

  cx-checkout-progress {
    .cx-nav .cx-item {
      color: $filter-color;
      padding: 10px;
      font-size: 1rem;
    }

    .cx-list {
      background: $customer-titles;
    }

    .cx-link {
      color: $filter-color;
    }

    .cx-link.disabled {
      color: $checkout-progress-disabled;
    }
  }

  cx-delivery-mode .form-check .cx-delivery-label {
    width: 95%;
    align-items: center;
    margin: 10px;
  }

  .cx-review-payment-col:empty {
    display: none;
  }

  .cx-review-payment-col:empty+.cx-review-shipping-col {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0px;
  }

  .cx-review-payment-col:empty+.cx-review-shipping-col .cx-review-card-address {
    width: 50%;
    float: left;
    border-bottom: none;
    border-right: 1px solid rgba(0, 0, 0, 0.5);
  }

  .cx-review-payment-col:empty+.cx-review-shipping-col .cx-review-card-shipping {
    width: 50%;
    float: left;
  }

  .cx-review-payment-col:empty+.cx-review-shipping-col .cx-review-summary-card .card-body {
    padding: 5px 20px;
  }

  cx-delivery-mode .form-check {
    align-items: center;
  }
}

.KnBrProductDetailsPageTemplate cx-page-slot.Tabs button.btn {
  // background-color: var(--cx-color-primary);
  // border: 1px solid var(--cx-color-primary);
}

.KnBrProductListPageTemplate,
.KnBrProductGridPageTemplate,
.KnBrSearchResultsListPageTemplate,
.KnBrSearchResultsGridPageTemplate {
  .product-action-section select.form-control {
    padding: 20px;
    height: 40px;
  }
}

.brandmenu-titles a {
  color: $filter-color;
  font-weight: 600;
  text-transform: capitalize;

}

.brandmenu-titles a:hover {
  color: var(--cx-color-primary);
  font-weight: 600;
}
.brandmenu-titles {
  >cx-generic-link {
    a {
      &:hover {
        border-bottom: 2px solid;
        padding-bottom: 10px;
      }
    }
  }
}


// Product-Grid Styling ends
// create-cart Styling starts
.valid-to .calendar {
  position: absolute;
  right: 0;
  right: 12px;
  top: 3px;
}

.valid-to .ngb-dp-arrow-btn {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
}

span.res-titles {
  display: none;
}

.menu-contents {
  position: absolute;
  display: none;
  z-index: 10;
  border: 1px solid #ccc;
  height: auto;
  flex-direction: column;
  transition: opacity 0.4s;
  z-index: 15;
  top:30px;
}

.menu-contents .menu-inner-titles a {
  text-transform: initial;
}

.brand-menu h5.brandmenu-titles:hover .menu-contents {
  display: flex !important;
  max-height: 450px;
  overflow: auto;
  min-height: 260px;
  transition: ease-in-out all .4s;
}

.menu-contents .menu-inner-titles:hover .menu-contents-inner {
  display: block !important;
  overflow: auto;
}

.menu-contents-inner {
  position: absolute;
  display: none;
  top: 0;
  transition: opacity 0.4s;
  left: 50%;
  height: 100%;
}

.menu-contents.menu-inner-last {
  left: 280px;
}

.customersearch-list-table .customersearch-list-titles input.form-control {
  padding: 15px;
}

.customersearch-list-table .customersearch-list-titles .valid-to .calendar {
  top: 3px;
}

.modal-content {
  border-radius: $nav-tabs-border-radius;
}

.SiteLogo .top-bar-nav {
  display: none;
}

header .header .SiteLogo .top-bar-nav {
  display: block;
}

.cursor-pointer {
  cursor: pointer;
}

// Counter Label Css

kn-br-add-to-cart {
  .quantity {
    @extend .color-font;
    display: inline-flex;
    margin-bottom: 20px;

    label {
      @extend .font-text-600;
      @extend .mr-2;
    }
  }
}

kn-br-product-list-item {
  kn-br-add-to-cart {
    form {
      display: inline !important;
    }
  }
}

.login-forms .form-group ngb-datepicker.dropdown-menu.show {
  z-index: 10009;
}

.filter-title button.close {
  display: none;
}

.modal-header,
.modal-footer {
  border: none;
}

.customersearch-forms.login-forms.modal-comments-list {
  max-height: 300px;
  overflow: auto;
}

.knbr-quote-reference {
  max-height: 250px;
  overflow: auto;
}

fieldset {
  min-width: 100%;
}

.product-main-grid.quote-ref-modal .alert.alert-warning {
  min-height: auto;
  float: left;
}

.KnBrQuoteActionBarSlot kn-br-quote-attachment-link {
  float: left;
  width: auto;
  flex: auto;
  max-width: 75%;
}

.KnBrDraftOrderActionBarSlot kn-br-draft-order-attachment-link {
  float: left;
  width: auto;
  flex: auto;
  max-width: 75%;
}

body .upload-files {
  label.upload-button {
    min-width: auto;
    padding: 0px;
    align-items: center;
    justify-content: center;
    display: flex;
    border: none;
  }

  .upload-text {
    display: none;
  }

  file-upload.file-upload-section {
    float: left;
  }

  label.upload-button i.fa {
    font-size: 18px;
    max-height: 40px;
  }
}

.upload-files label {
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  max-width: 380px;
  min-height: 43px;
  padding: 10px;
}

.upload-files label a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.upload-files {
  max-width: 82%;
}

.upload-files .btn {
  background: $selected-filter;
}

.d-contents {
  display: contents;
}

.count-currency {
  font-size: 2.25rem;
  color: var(--cx-color-inverse);
  line-height: initial;
}

.KnBrQuoteDetailPageTemplate {
  knbr-kn-br-quote-download-link {
    flex: auto !important;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    min-width: 13%;
    max-width: max-content;
  }
}

.pending-approved-box {
  border: 1px solid #bbe4e2;
  background: #bbe4e2;
  border-radius: 5px;
  height: 30px;
  text-align: center;
  /* width: 50%; */
  display: inline-block;
}

.KnBrDraftOrderDetailPageTemplate {
  knbr-kn-br-draft-order-download-link {
    flex: auto !important;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    min-width: 13%;
    max-width: max-content;
  }
}

.SearchBox cx-searchbox {
  z-index: 10;
  label {
    min-width: 205px;
  }
}

.cx-searchbox{
  background-color:var(--cx-color-inverse) ;
}
.SearchBox cx-searchbox .results {
  z-index: 0;
}

header cx-page-layout.header .SearchBox cx-searchbox .results {
  overflow-y: auto;
  max-height: 80vh;
}

main .SearchBox cx-searchbox .results {
  overflow-y: auto;
  max-height: 60vh;
}

.ng-select.ng-select-opened .ng-select-container {
  z-index: inherit !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-left: 0px;
  .ng-placeholder {
    padding-left: 16px;
  }

  .ng-value {
    margin-left: 18px;
  }
}

.KnBrOrderDetailsPageTemplate {
  knbr-kn-br-order-details-actions .btn {
    padding: 10px;
    font-size: 1rem;
    height: auto;
  }
}

.KnBrOrderDetailsPageTemplate,
.CarlisleInvoiceDetailsPageTemplate,
.KnBrReturnDetailsPageTemplate {
  .text-muted {
    color: aqua;
  }

  .BodyContent cx-link a {
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    font-size: 1rem;
    height: auto;
    text-transform: capitalize;


    &:hover {
      color: var(--cx-color-inverse);
      border-color: var(--cx-color-primary);
      background-color: var(--cx-color-primary);
    }

    &:not(:disabled):not(.disabled):active {
      color: var(--cx-color-inverse);
      border-color: var(--cx-color-primary);
      background-color: var(--cx-color-primary);
    }

    &.disabled {
      color: var(--cx-color-light);
      border-color: var(--cx-color-light);
      opacity: 1;
    }
  }

  .BodyContent cx-link {
    display: flex;
    justify-content: center;
    margin: 1.125rem;
  }
}

cx-page-layout.KnBrOrderDetailsPageTemplate knbr-kn-br-order-details-items cx-media {
  width: 85%;
  min-height: 85px;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
}

cx-page-layout.KnBrOrderDetailsPageTemplate knbr-kn-br-order-details-items {
  .header {
    label {
      position: absolute;
      top: -10px;
      left: 20px;

      font-size: 14px;
      color: $gray-800;
      padding: 0px 5px;
      background: var(--cx-color-inverse);
      z-index: 1;
    }
    ng-select {
      border-radius: 4px;
    }
  }
}

.KnBrOrderImportPageTemplate {
  knbr-order-import .btn {
    padding: 10px;
    font-size: 1rem;
    height: auto;
  }
}

.KnBrReturnDetailsPageTemplate {
  knbr-order-return .btn {
    padding: 10px;
    height: auto;
  }
}

.bg-light-gray {
  background: $customer-titles;
}

.KnBrOrderImportPageTemplate file-upload .upload-text {
  display: none;
}

.KnBrOrderImportPageTemplate file-upload {
  border: none;
}

.KnBrOrderImportPageTemplate file-upload .upload-button {
  border: none;
}

.order-tools .dropdown-item:hover,
.order-tools .dropdown-item:focus {
  color: var(--cx-color-inverse);
  text-decoration: none;
  background-color: var(--cx-color-primary);
}

.order-tools .btn-primary {
  color: var(--cx-color-inverse);
  background-color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
}

.order-tools .btn-primary.dropdown-toggle {
  height: auto;
  color: var(--cx-color-inverse);
  background-color: var(--cx-color-primary);
  border-color: var(--cx-color-primary);
  font-size: 14px;
}

.doc-viewer-modal-header .mr-left-auto {
  margin-left: auto;
}

.doc-viewer {
  width: 100%;
  height: 93vh;
}

.doc-viewer-modal-header .margin-zero {
  margin: 0px 0px 0px 0px;
}

.dropdown .dropdown-menu:before {
  content: ' ';
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid var(--cx-color-primary);
  position: absolute;
  top: -13px;
  left: -58px;
}

.dropdown .dropdown-menu:before {
  left: 82% !important;
}

.address-header {
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
}

.address-item {
  font-size: 15px;
  font-weight: 500;
}

.address-card-link {
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
  text-decoration: underline;
  display: inline-block;
  color: var(--cx-color-primary);
  cursor: pointer;
}

.address-card-link:hover {
  text-decoration: underline;
  color: var(--cx-color-primary);
  cursor: pointer;
}

.address-form {
  font-size: 15px;
}

.address-form .form-control {
  font-size: 15px;
  margin-bottom: 0;
  height: auto;
}

knbr-address-form .address-form .ng-select-container {
  font-size: 15px;
  margin-bottom: 0;
  height: auto !important;
  line-height: 1.5 !important;
}

.address-card {
  min-height: 202px;
}

.KnBrServiceTicketsPageTemplate file-upload .upload-text {
  display: none;
}

.KnBrServiceTicketsPageTemplate file-upload {
  border: none;
}

.KnBrServiceTicketsPageTemplate file-upload .upload-button {
  border: none;
  padding: 0;
}

cx-pagination a {
  width: 44px;
  height: 42px;
}

cx-page-layout.KnBrQuickOrderPageTemplate cx-media {
  height: 70px;
}

cx-page-layout.KnBrRegistrationPageTemplate {
  background: var(--cx-color-inverse);
  padding: 15px;
  width: auto;
  max-width: 100%;

  .SideContent kn-br-paragraph p {
    color: $text-muted;
  }

  .SideContent kn-br-paragraph b {
    color: $filter-color;
    font-size: 18px;
    font-weight: 500;
  }
}

.NACPageTemplate file-upload .upload-text {
  display: none;
}

.NACPageTemplate file-upload {
  border: none;
}

.NACPageTemplate file-upload .upload-button {
  border: none;
  padding: 0;
}

.uploadTemplate file-upload .upload-text {
  display: none;
}

.uploadTemplate file-upload {
  border: none;
}

.uploadTemplate file-upload .upload-button {
  border: none;
  padding: 0;
}

.modal .close {
  align-self: auto;
}

cx-page-layout.KnBrOrderFormsPageTemplate cx-media {
  height: 70px;
}

.quick-link:hover {
  -webkit-box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  -moz-box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: inset 0px 0px 6px #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

cx-page-layout.KnBrCustomer360PageTemplate {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1024px) {
    .btn-transparent-bg{
      left:86% !important;
    }
    cx-page-slot.TopContent {
      width: 50%;
      flex: 1 1 50%;
      padding-right: 10px;
    }

    cx-page-slot.MiddleContent {
      width: 50%;
      flex: 1 1 50%;
      padding-left: 10px;
    }
  }

  cx-page-slot.BottomContent {
    margin-top: 15px;
  }

}

cx-page-layout.KnBrMarketCentrePageTemplate {
  knbr-quick-links {
    flex: 1 1 var(--cx-flex-basis, 50%) !important;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.btn-link {
  color: var(--cx-color-primary);

  &:hover {
    color: var(--cx-color-primary);
  }
}

body.searchbox-is-active.has-searchbox-results cx-searchbox .searchbox.dirty+.results {
  display: block;
}

body.searchbox-is-active.has-searchbox-results cx-searchbox .searchbox+.results {
  display: none;
}

cx-searchbox .searchbox.dirty cx-icon.reset {
  display: block !important;
}

cx-searchbox .searchbox cx-icon.reset {
  display: none !important;
}

cx-searchbox .searchbox.dirty cx-icon.search {
  display: none !important;
}

cx-searchbox .searchbox cx-icon.search {
  display: block !important;
}

// User Management CSS Start

:root {
  --cx-split-gutter: 20px;
}

cx-page-layout.CompanyPageTemplate {
  .BodyContent {
    margin: 0;
  }

  cx-org-list cx-split-view {
    padding: 10px 0;
  }

  cx-org-list cx-table thead th {
    height: auto;
    text-transform: capitalize;
    font-size: 1rem;
  }

  cx-org-list cx-table td a {
    text-decoration: none !important;
    min-height: auto;
    padding: 0;
  }

  cx-org-list cx-table.vertical td {
    height: auto;
  }

  cx-org-list .header .title cx-icon.fa-info-circle {
    display: none;
  }

  cx-org-list .list .header {
    padding: 0 0 15px 0;
    height: 115px;
  }

  cx-org-list.orgUser .list .header .actions .sort {
    display: none;
  }

  cx-org-list .list .header .actions a,
  cx-org-list .list .header .actions button {
    text-transform: capitalize;
  }

  cx-org-list .list .header h3 {
    margin: 0 0 15px 0;
    text-transform: capitalize;
  }

  cx-org-list .card .header {
    height: 115px;
    padding: 0 30px;
  }

  cx-org-list .card .header .actions a,
  cx-org-list .card .header .actions button {
    text-transform: capitalize;
  }

  cx-org-list .card .header h3 {
    text-transform: capitalize;
  }

  cx-org-list .card .header h4 {
    margin: 0;
  }

  cx-org-list .card section {
    margin: 20px;
  }

  cx-org-list .card section.details {
    padding: 20px 0px 20px 20px;

    label .label-content {
      text-transform: capitalize;
    }
  }

  cx-org-list .card section.details .property {
    margin-bottom: 20px;
  }

  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
    top: 9px;
  }
}

// User Management CSS End

.KnBrLoginPageTemplate cx-asm-main-ui~main cx-page-layout.KnBrLoginPageTemplate .SiteLogo {
  display: none;
}

.KnBrLoginPageTemplate cx-asm-main-ui.hidden~main cx-page-layout.KnBrLoginPageTemplate .SiteLogo {
  display: flex;
}

.KnBrLoginPageTemplate cx-asm-main-ui+header {
  display: none;
}.highlight-menu {
  background: $gray-300;
}

table thead th {
  text-transform: capitalize !important;
}

cx-storefront.asm-storefront cx-page-layout.header,
cx-storefront.asm-storefront .breadcrumb {
  position: static;
}

cx-storefront.asm-storefront main cx-page-layout {
  margin: 15px 15px;
}

cx-storefront.asm-storefront main cx-page-layout.KnBrLandingPage2Template {
  margin: 0;
}

knbr-kn-br-reset-password.user-form {
  position: static;
}

.border-2 {
  border: 2px solid #8b9196;
  margin-bottom:15px;
}

.border-1 {
  border-top: 1px solid #495057;
}

.cart-icon {
  margin-right: 10px !important;
}

.header-info{
  color: var($text-muted) !important;
}
.header-icon-color{
  color: var(--cx-color-primary);
}
label.searchbox{
  background-color: var(--cx-color-inverse);
  border: 1px solid var(--cx-color-light);
}
cx-searchbox label input{
  height: 22px;
  color: $search-grey-100;
}
.btn-transparent-bg{
  border: none;
  background: transparent;
  position: relative;
  top: -38px;
  right: -250px;
}

.carousel-panel .next{
  position: relative;
  right: -80px;
}
.knbr-footer-bottom{
  background-color: var(--cx-color-inverse);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: solid 1px #ccc;
}
.color-black{
  color: $black-color;
}


cx-carousel .carousel-panel .slides .slide .item.active{
  border: 1px solid #8b9196 !important;
  border-radius: 4px;
  margin: .6rem;
  padding: 1rem;
}

.carousel-title{
  color: var(--cx-color-primary);
}
.carousel-title h2{
  margin-bottom: 1.5rem;
}
.item-details-height{
  height: 100px;
}
.code-number{
  display: block;
}

.sort-label-width{
  width: 65px;
}
.font-weight700{
  font-weight:700;
  height: 40px;
}
.KnBrLandingPage2Template .Section4{
  flex-wrap: wrap!important;
  knbr-kn-br-spotlight {
    &:nth-child(even) {
      .how-img {
        /*order: 2!important;*/
      }
    }
    &:not(:first-child) {
      .how-section1 > h2 {
        display: block;
      }
    }
  }
}
/* Fading animation */
.fadespot {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fadespot {
  from {opacity: .4}
  to {opacity: 1}
}

.order-details-table-row-height{
  padding-top: 1rem;
  min-height: 4rem;
}
.doc-link{
  color: var(--cx-color-primary) !important;
}
.color-knbr-footer {
  background-color:var(--cx--color-sec-gray);
}
.p-5 {
  padding: 1rem !important;
}
.footer-p-5 {
  padding: 26px 69px 18px 69px !important;
  font-feature-settings: "pcap", "c2pc";
  font-variant: all-petite-caps;
  font-family: Arial, Helvetica, sans-serif;
}
.footer-colums{
  width: 50%;
  float: left;
}

.Footer{
  position: relative;
}
 .vooter_ccmaterials{
  position: absolute;
  left: 65%;
  top:53px;
  min-height: 250px;
 }
  .vooter_materials_two{
  position: absolute;
  left: 65%;
  top:125px;
  width: 25%;
  float: left;
 }

 .vooter_ccmaterials h4, .vooter_materials_two h4{
  font-size: .8rem;
  font-style: italic;
 }
 .vooter_ccmaterials div, .vooter_materials_two div{
  font-size:  0.645rem;
 }

 .crl_materials_link{
  text-decoration: underline;
  font-size:  0.7rem;
  color: #fff !important;
 }

 @media (max-width: 1023px) {
  .btn-transparent-bg{
    left:86% !important;
  }
  .carousel-panel .next{
    position: relative;
    right: -30px;
  }
.footer-colums{
  width: 100%;
  clear: both;

}
.p-5 {
  padding: 2rem !important;
}
.vooter_ccmaterials {
    position: absolute;
    left: 53%;
    top: 64px;
    min-height: 250px;
    width: 40%;
}
}
@media (max-width: 1024px) {
  .vooter_materials_two {
    position: absolute;
    left: 53%;
    top: 200px;
    width: 35%;
    float: left;
}
}
.footer kn-br-paragraph{
  background-color: var(--cx--color-sec-gray);
  }
.loader-center-align{
    margin-right:25% ;
  }
  // .font-weight-bold{
  //   font-weight: bold;
  //   color: var(--cx--color-sec-gray);
  //   font-size: 14px;

  // }
  .color-knbr-primary {
    background-color:var(--cx--color-sec-gray)!important;
}
.accordion .card-header{
  font-size: 16px !important;
}
.btn.btn-link{
 font-size: 15px !important;
}
.bg-white.product-cate-options{
  margin-top: 9px;
}
.accordion .card{
  margin-bottom: 10px;
}
cx-page-layout.KnBrProductGridPageTemplate .filter-by .accordion .collapse{
  min-height: auto !important;
}
.font-weight-bold {
  font-weight: bold !important;
  color: var(--cx--color-sec-gray) !important;
  font-size: 14px !important;
  background-color: #e2e2e4;
}
.bg-white.product-cate-options{
  margin-top: 9px;
}
.btn-link {
  font-size:14px !important;
}
cx-page-layout.KnBrOrderDetailsPageTemplate{
  margin-top: 50px;
}
cx-page-layout.CarlisleInvoiceDetailsPageTemplate{
  margin-top: -8px;
}
.KnBrOrderDetailsPageTemplate knbr-kn-br-order-details-overview .float-right.d-flex.justify-content-end{
  margin-top: -65px;
}
.vooter_ccmaterials h4, .vooter_materials_two h4 {
  font-size: 0.8rem;
  font-style: italic;
  font-weight: bold;
}
main cx-page-layout.LandingPage2Template {
  padding:1px 15px 15px 15px;
  max-width: 100%;
  margin-top: 108px;
}

.vooter_ccmaterials{
  position: absolute;
  left: 65%;
  top:25px !important;
  min-height: 250px;
 }
 .vooter_materials_two{
  top: 155px !important;
 }
.float-left.header-info:hover, #dropdownBasic1:hover{
  color: $search-grey-100;
}
cx-page-layout.KnBrSearchResultsGridPageTemplate .filter-by .accordion .collapse{
  min-height: auto !important;
}
.color-light-bg.color-font.font-size.mr-2.mt-2.mb-2.float-left.clr{
  background-color: var(--cx--color-sec-gray) !important;
  color: #fff !important;
}


%cx-media {
  &.is-missing {
    background: no-repeat top center url('../images/no-image/CCM-16539\ Self\ Service\ Portal-Image\ Coming\ Soon_515x515.jpg');
    background-size: contain;
    min-height: 80px;
  }
}
.text-wrap{
  padding-left: 7px !important;
}

.KnBrLandingPage2Template {
  %cx-media {
    &.is-missing {
      min-height: 180px!important;
    }
  }
}

.cursor-auto {
  cursor: auto!important;
}

.KnBrLandingPage2Template {
  .Section5 {
    margin: 50px;
    padding: 0;
    position: relative;
    z-index: 0;
    kn-br-banner {
      margin: 0 auto;
      padding: 30px;
      position: relative;
      max-width: 1320px;
      display: flex;
      gap: 0 2%;
      cx-generic-link {
        margin: 0;
        padding: 0 20px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #FFF;
        box-shadow: 0 6px 9px #a5a7aa;
        border-radius: 15px;
        border: 1px solid #a5a7aa;
        img {
          max-width: 100%;
          margin: 0 auto;
        }
      }
    }
  }
}

.scheduled {
  display: table-footer-group;
  .card {
    margin-bottom: 10px;
    &-header {
      padding: 0px;
      background-color: white;

      .btn-link {
        color: #6c757d !important;
        font-size: 18px !important;
        text-decoration: none;
        padding-left: 0px;
        padding-bottom: 0px;
      }
    }
  }

  .collapse {
    .card-body {
      padding: 0px;
    }
  }
}

app-kn-br-invoice-account-summary{
  flex: 1 1 40%!important;
  > div {
    height: 318px;
  }
}

app-kn-br-invoice-due-balance{
  flex: 1 1 60%!important;
  > div {
    height: 318px;
  }
}

.border-right-0 {
  border-right: none;
}

.border-left-0 {
  border-left: none;
}

.dateControls::-webkit-input-placeholder { color: #929292 };
